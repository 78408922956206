import {Translation} from '../../../_models/translation';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TranslationsService {
  translationsUrl = environment.apiUrl + '/translations';
  defaultpath = '/assets/language/';
  chosenLanguage = new BehaviorSubject<string>('pl');
  path = new BehaviorSubject<string>(this.defaultpath + 'pl/');

  constructor(private httpClient: HttpClient) {
  }

  getTranslations(): Observable<Record<string, string>> {
    return this.httpClient.get<Translation[]>(this.translationsUrl).pipe(
      map((translations) => {
        return translations.reduce((prev, curr) => {
          prev[curr.translationKey] = curr.translationValue;
          return prev;
        }, {} as Record<string, string>);
      })
    );
  }

  getLanguageAssetPath(): Observable<string> {
    return this.path.asObservable();
  }

  getLanguage(): Observable<string> {
    return this.chosenLanguage.asObservable()
  }

  setLanguage(language: string) {
    this.chosenLanguage.next(language)
    this.path.next(this.defaultpath + language + '/')
  }
}
