import { environment } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Seo, JsonLd } from 'src/_models/seo';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  defaultSeo: Seo = {
    title: 'Sushi Poznań - restauracja japońska, sushi bar, sushi na wynos',
    canonical: '',
    description:
      '→ Klasyczyna japońska restauracja ✓ Zarezerwuj online lub zadzwoń ✓ Sprawdź menu !',
  };
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  updateSeo(seo?: Seo): void {
    seo ??= this.defaultSeo;
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link');
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', environment.baseUrl + seo.canonical);
    this.titleService.setTitle(seo.title + ' | Sushi Poznań Drzewo Wiśni');
    this.metaTagService.updateTag({
      name: 'description',
      content: seo.description,
    });
    this.updateOGTags(seo);
    this.setJsonLd(seo.jsonLd);
  }

  private setJsonLd(jsonLd?: JsonLd): void {
    let ldJsonScriptTag = this.dom.head.querySelector(
      `script[type='application/ld+json']`
    );
    if (ldJsonScriptTag) {
      ldJsonScriptTag.textContent = JSON.stringify(jsonLd);
    } else {
      ldJsonScriptTag = this.dom.createElement('script');
      ldJsonScriptTag.setAttribute('type', 'application/ld+json');
      ldJsonScriptTag.textContent = JSON.stringify(jsonLd);
      this.dom.head.appendChild(ldJsonScriptTag);
    }
  }

  updateOGTags(seo: Seo): void {
    this.metaTagService.updateTag({
      property: 'og:site_name',
      content: 'Drzewo Wiśni',
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: environment.baseUrl + seo.canonical,
    });
    this.metaTagService.updateTag({
      property: 'og:type',
      content: seo.type || 'website',
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: seo.title + ' | Sushi Poznań Drzewo Wiśni',
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: seo.description,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: seo.image ? environment.baseUrl + seo.image : '',
    });
  }
}
