export const environment = {
  baseUrl: 'https://drzewowisni.pl',
  databaseUrl: 'postgres-db',
  databaseName: 'postgres',
  databaseUser: 'postgres',
  databasePassword: 'docker',
  databasePort: 5432,
  apiUrl: 'https://drzewowisni.pl/api',
  googleTagManagerId: 'GTM-P6PGFK4',
  production: true,
  TOKEN_SECRET:
    'zS`bt__grE_c_gcTZe;z0`7]??ug=F<z7gvj<66Mo^eDCyX?N4V=irAyBMCcHz`yy=;Ersd86zc^]yP1tVYb9osLY_6wNj354Gf<WsaC?]?lq96SREA2g3KDuI:q^Unt',
  reservationsSummaryEmailAddresses: [
    'katarzyna.wujczak@datasystem.pl',
    'tomasz.waligora@icloud.com',
    'marek.wroblewski@drzewowisni.pl'
  ],
  reservationEmailAddresses: ['tomasz.waligora@icloud.com', 'marek.wroblewski@drzewowisni.pl'],
  reservationSMSAddresses: [],
  reservationVehicleEmailAddress: ['tomasz.waligora@icloud.com'],
  overdueTasksEmail: ['tomasz.waligora@icloud.com', 'marek.wroblewski@drzewowisni.pl'],
  clientId: 'e279b560-17a9-4c57-844b-436e30755aa9',
  clientSecret: '68e182ac-f9d3-425e-ad4d-3612dab48f5f',
  username: 'arkadiusz.przytula@datasystem.pl',
  password: 'Demolka777$',
  url: 'https://app.gopos.io/',
  organization: '1796',
  facebookAccessToken: 'EAAFVEwWF1mkBO1B8MGEU7ZCH0TDtyIJu3ACdHC9cZBiaTXzaJRcOeJyKWpadgo1twlEbvGWoZAszAgFZBNd24WGq4vD3ZAg5BqiByKu8WXf9pm5WVWZCYqAbYTQcQ4d0FIUDDKGhK66kPam8UfJkNEAa3NnQOQ1xX6mvwVA9VHZArewQAA8q0QonfmtZAy6gfloZD'
};
