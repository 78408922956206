import { Seo } from './../_models/seo';
import { TranslationsService } from './_services/translations/translations.service';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BehaviorSubject, filter, map, mergeMap} from 'rxjs';
import { SeoService } from './_services/seo/seo.service';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Drzew Wiśni';
  static isBrowser = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private translationsService: TranslationsService,
    private changeRef: ChangeDetectorRef,
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit(): void {
    this.translationsService.getTranslations().subscribe((translations) => {
      loadTranslations(translations);
      this.changeRef.markForCheck();
    });

    this.router.events
      .pipe(
        filter((val): val is NavigationEnd => val instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => {
          return route.outlet === 'primary';
        }),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { seo?: Seo }) => {
        this.seoService.updateSeo(event.seo);
      });

    const buttons = document.querySelectorAll('.ds-widget-chat-button');
    if (buttons) {
      setTimeout(() => {
        buttons.forEach((button: any) => {
          button.click();
        });
      }, 10000);
    }

  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
